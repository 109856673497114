import React from 'react'
import { DateTime } from 'luxon'
import Avatar from './blocks/Avatar'
import Divider from './blocks/Divider'
import Socials from './blocks/Socials'
import Text from './blocks/Text'
import Button from './blocks/Button'
import Image from './blocks/Image'
import Video from './blocks/Video'
import Html from './blocks/Html'
import SubmitForm from './blocks/SubmitForm'
import InstagramPost from './blocks/InstagramPost'
import Carousel from './blocks/Carousel'
import Timer from './blocks/Timer'
import * as css from './Landing.less'

export interface LandingData {
  id: number,
  link: string,
  name: string,
  locale: 'ru' | 'en',
  owner: {
    id: number
    username: string
    avatarUrl: string
  }
  tariff: 'Free' | 'Standard' | 'Premium' | 'Lifetime'
  blocks: Block<any>[]
  settings?: {
    title?: string
    keywords?: string
    backgroundColor?: string
    backgroundColorTo?: string
    footer: boolean
  }
}

export interface Block<C extends Object> {
  id: number,
  blockType: string,
  orderNum: number,
  content: C
}

interface PageProps {
  landingData: LandingData
}

export interface BlockProps<C = any> {
  onClick: (content?: any) => void
  landing: LandingData
  block: Block<C>
}

export default class extends React.Component<PageProps, any> {

  state = {
    isSending: false
  }

  handleClick(block: Block<any>, content?: any) {
    if (this.state.isSending) {
      return
    }
    this.setState({isSending: true})
    const options = {
      method: 'POST',
      body: JSON.stringify({content: content || null}),
      headers: {'Content-Type': 'application/json'},
    }

    return fetch(`/api/public/register_click/${block.id}`, options)
      .then(() => this.setState({isSending: false}))
      .catch(() => this.setState({isSending: false}))
  }

  isBlockVisible(block: Block<any>): boolean {
    if (!block.content.visibility) {
      return true;
    }
    const { type, timeRange, dateRange, days } = block.content.visibility;
    const date = DateTime.local();
    switch (type) {
      case 'byTime':
        const today = date.toUTC().toISO({includeOffset: false}) + 'Z';
        return !(dateRange.start && today < dateRange.start || dateRange.end && today > dateRange.end);
      case 'bySchedule':
        if (days.length && !days.includes(date.weekday - 1)) {
          return false;
        }
        const time = date.toFormat('HH:mm');
        return !(timeRange.start && time < timeRange.start || timeRange.end && time > timeRange.end);
      default:
        return true;
    }
  }

  getLadingStyle(data: LandingData) {
    if (!data.settings) {
      return {}
    }
    const { backgroundColor, backgroundColorTo } = data.settings;
    if (backgroundColorTo) {
      return { background: `linear-gradient(${backgroundColor}, ${backgroundColorTo})` }
    }
    return { backgroundColor }
  }

  renderFooter(landingData: LandingData) {
    if (landingData.locale === 'ru') {
      return (
        <footer>
          <a href={'https://avatap.ru/invite/' + landingData.owner.id}>
            Сделано на avatap.ru
          </a>
        </footer>
      );
    }
    return (
      <footer>
        <a href={'https://avat.app/invite/' + landingData.owner.id}>
          Made with avat.app
        </a>
      </footer>
    );
  }

  renderBlock(block: Block<any>, landingData: LandingData) {
    const onClick = (content?: any) => this.handleClick(block, content)
    switch (block.blockType) {
      case 'avatar':
        return <Avatar key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'divider':
        return <Divider key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'submitForm':
        return <SubmitForm key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'messengers':
      case 'socials':
        return <Socials key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'text':
        return <Text key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'button':
        return <Button key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'image':
        return <Image key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'video':
        return <Video key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'html':
        return <Html key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'instagram-post':
        return <InstagramPost key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'carousel':
        return <Carousel key={block.id} block={block} onClick={onClick} landing={landingData}/>
      case 'timer':
        return <Timer key={block.id} block={block} onClick={onClick} landing={landingData}/>
      default:
        return <p key={block.id}>Unknown block type: {block.blockType}</p>
    }
  }

  renderBlocks(landingData: LandingData) {
    return landingData.blocks
      .map(block => {
        if (!this.isBlockVisible(block)) {
          return null;
        }
        const { blockBackgroundColor, blockBackgroundColorTo } = block.content;
        const background = blockBackgroundColorTo ?
          `linear-gradient(${blockBackgroundColor}, ${blockBackgroundColorTo})` :
          blockBackgroundColor;
        return (
          <div key={block.id} style={{ background }}>
            { this.renderBlock(block, landingData) }
          </div>
        )
      })
  }

  render() {
    const {landingData} = this.props
    const blocks = this.renderBlocks(landingData)
    const isFooter = !landingData.settings || landingData.settings.footer
    return (
      <div className={css.content} style={this.getLadingStyle(landingData)}>
        <React.Fragment>
          {blocks}
        </React.Fragment>
        { isFooter && this.renderFooter(landingData) }
      </div>
    )
  }

}
