import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './InstagramPost.less';

export default class extends React.Component<BlockProps, any> {

  render() {
    const { block, onClick } = this.props;
    const { link, post } = block.content
    return (
      <div className={css.instagramPost}>
        <a className='g-full-width' href={link} target='_blank' onClick={() => onClick()}>
          <img src={post.srcUrl} alt=''/>
        </a>
        {post.description && <p>{post.description}</p>}
      </div>
    )
  }

}
