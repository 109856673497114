import React from 'react'
import { BlockProps } from '../Landing'
import * as css from './Socials.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons'
import {
  faTelegram, faVk, faOdnoklassniki, faInstagram, faYoutube,
  faTwitter, faGooglePlusG, faLinkedinIn, faTumblr, faFlickr,
  faFacebookF, faPinterest, faViber, faWhatsapp, faSkype
} from '@fortawesome/free-brands-svg-icons'

const SOCIALS: {id: string, icon: IconDefinition}[] = [
  {id: 'Vk', icon: faVk},
  {id: 'Facebook', icon: faFacebookF},
  {id: 'Telegram', icon: faTelegram},
  {id: 'Youtube', icon: faYoutube},
  {id: 'Instagram', icon: faInstagram},
  {id: 'Odnoklassniki', icon: faOdnoklassniki},
  {id: 'Moimir', icon: faAt},
  {id: 'Pinterest', icon: faPinterest},
  {id: 'Twitter', icon: faTwitter},
  {id: 'Google', icon: faGooglePlusG},
  {id: 'Linkedin', icon: faLinkedinIn},
  {id: 'Tumblr', icon: faTumblr},
  {id: 'Flickr', icon: faFlickr},
  {id: 'Whatsapp', icon: faWhatsapp},
  {id: 'Viber', icon: faViber},
  {id: 'Skype', icon: faSkype},
  {id: 'Phone', icon: faPhone},
];

interface SocialsItem {
  id: string
  icon: string
  size?: string
  link: string
}

interface SocialsBlockContent {
  title: string
  socials: SocialsItem[]
  // ToDo: remove?
  messengers?: SocialsItem[]
}

export default class extends React.Component<BlockProps<SocialsBlockContent>, any> {

  getClassName(item: SocialsItem) {
    return [
      css.socials__item,
      'socials-icon',
      `socials-icon--${item.id}`,
      `socials-icon--${item.size || 'small'}`
    ].join(' ');
  }

  render() {
    const { block, onClick } = this.props;
    const list = block.content.socials || block.content.messengers;
    const socialsItems = list.map(item => {
      if (!item) {
        return null;
      }
      const social = SOCIALS.find(s => s.id === item.id);
      if (!social) {
        return null
      }
      let href: string = item.link;
      switch (item.id) {
        case 'Phone':
          href = 'tel:' + href;
          break;
        case 'Skype':
          href = 'skype:' + href + '?call';
          break;
        case 'Viber':
          href = 'viber://add?number=' + href.replace('+', '');
          break;
        case 'Whatsapp':
          href = 'whatsapp://send?phone=' + href.replace('+', '');
      }
      return (
        <a
          className={this.getClassName(item)}
          key={item.id}
          href={href}
          target='_blank'
          onClick={onClick.bind(null, {social: item.id})}
        >
          <FontAwesomeIcon icon={social.icon} size={item.size !== 'large' ? 'lg' : '1x'}/>
        </a>
      );
    });
    return (
      <div className={css.socials}>
        {block.content.title && (
          <header>
            <h3 className={css.socials__title}>{block.content.title}</h3>
          </header>
        )}
        <div className={css.socials__list}>
          {socialsItems}
        </div>
      </div>
    )
  }

}
