import React, { Component, ReactNode } from 'react';
import { BlockProps } from '../Landing';
import SvgTag from '../svg/SvgTag';
import * as css from './Image.less';
import ResponsiveImage from '../shared/ResponsiveImage';

interface ImageTag {
  icon: boolean
  title: string
  link: string
  price: string
  transform?: string
}

interface ImageData {
  imageUrl?: string
  imageSize?: {
    width?: string
    height?: string
  }
  link?: string
  text?: string
  tags?: ImageTag[]
}

interface ImageState {
  showTags: boolean
  widthDiff: number
}

interface ImageWrapperProps {
  children: ReactNode[]
  data: ImageData
  onClick: () => void
}

function ImageWrapper({data, onClick, children}: ImageWrapperProps) {
  const style: {[k: string]: string} = { position: 'relative' }
  if (data.imageSize) {
    style.width = data.imageSize.width || '100%'
    style.height = data.imageSize.height || 'auto'
    style.margin = '0 auto'
  }
  if (data.link && (!data.tags || !data.tags.length)) {
    let redirectUrl = data.link;
    if (!redirectUrl.startsWith('http') && !redirectUrl.startsWith('//')) {
      redirectUrl = 'http://' + redirectUrl;
    }
    return <a style={style} href={redirectUrl} target='_blank'>{children}</a>
  }
  return <div style={style} className='g-full-width' onClick={onClick}>{children}</div>
}

const PREVIEW_SCREEN_WIDTH = 300;

export default class extends Component<BlockProps<ImageData>, ImageState> {
  element: HTMLDivElement | null = null;
  state = {
    showTags: false,
    widthDiff: 0
  }

  componentDidMount() {
    const widthDiff = this.element ? this.element.clientWidth / PREVIEW_SCREEN_WIDTH : 1;
    this.setState({ widthDiff });
  }

  parseTagTransform(transform?: string) {
    const { widthDiff } = this.state;
    if (!transform || widthDiff <= 1) {
      return transform || '';
    }
    const numbers = transform.match(/^translate3d\((\-?\d+)px, (\-?\d+)px, 0px\)/);
    if (numbers) {
      let [left, top] = numbers.slice(1).map(Number);
      left = 2 * widthDiff + left * widthDiff;
      top = 2 * widthDiff + top * widthDiff;
      return `translate3d(${left}px, ${top}px, 0px) scale(${widthDiff})`;
    }
    return transform || '';
  }

  handleImageClick = () => {
    const { showTags } = this.state;
    const { block: { content: { tags } }, onClick } = this.props;
    if (!showTags && tags && tags.length > 0) {
      this.setState({ showTags: true });
    }
    onClick();
  };

  renderTags(tags: ImageTag[]) {
    return tags.map((tag: ImageTag, i: number) => (
      <span
        key={i}
        className={css.tag}
        style={{transform: this.parseTagTransform(tag.transform)}}
        onClick={() => tag.link && window.open(tag.link)}
      >
        {tag.icon && <SvgTag />}
        {tag.title && (
          <div className={css.tag_info}>
            <span>{ tag.title }</span>
            <span>{ tag.price }</span>
          </div>
        )}
      </span>
    ));
  }

  render() {
    const { showTags } = this.state;
    const { block } = this.props;
    const { imageUrl, text, tags } = block.content
    return (
      <div className={css.image} ref={(ref) => this.element = ref}>
        <ImageWrapper data={block.content} onClick={this.handleImageClick}>
          {imageUrl && <ResponsiveImage src={imageUrl} />}
          {tags && tags.length > 0 && (
            <div>
              {showTags ?
                this.renderTags(tags) :
                <span className={css.tags_hint}>Коснитесь, чтобы посмотреть продукты</span>
              }
            </div>
          )}
        </ImageWrapper>
        {text && <p>{text}</p>}
      </div>
    )
  }

}
