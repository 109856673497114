import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Text.less';

export default class extends React.Component<BlockProps, any> {

  calcPaddingTop(offset?: string) {
    if (offset === 'double') {
      return '2rem';
    }
    if (offset === 'none') {
      return 0;
    }
  }

  render() {
    const { block, onClick } = this.props;
    const { text, textAlign, fontSize, fontWeight, fontStyle, color, offset } = block.content;
    return (
      <div className={css.text} style={{ paddingTop: this.calcPaddingTop(offset) }}>
        <p style={{ fontSize: fontSize + 'px', textAlign, fontWeight, fontStyle, color }} onClick={() => onClick()}>
          { text }
        </p>
      </div>
    )
  }

}
