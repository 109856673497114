import React from 'react'
import fetch from 'isomorphic-unfetch'
import { BlockProps } from '../Landing'
import * as css from './SubmitForm.less'

export default class extends React.Component<BlockProps, any> {

  state = {
    isSent: false,
    isSending: false,
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  handleChange(prop: {[key: string]: string}) {
    this.setState(prop);
  }

  handleSubmit(event: any) {
    event.preventDefault();
    if (this.state.isSending) {
      return;
    }
    this.props.onClick();
    this.setState({isSending: true});
    const {name, email, phone, message} = this.state;
    const options = {
      method: 'POST',
      body: JSON.stringify({name, email, phone, message}),
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    }

    return fetch(`/api/public/landing/${this.props.landing.id}/contact`, options)
      .then(() => this.setState({isSent: true}));
  }

  render() {
    const { block, landing } = this.props;
    const isRu = landing.locale === 'ru'
    return (
      <div className={css.submitForm}>
        {this.state.isSent ?
          <h3>{ isRu ?  'Отправлено' : 'Thanks!'}</h3> :
          <form onSubmit={event => this.handleSubmit(event)}>
            {block.content.title && (
              <h3>{block.content.title}</h3>
            )}
            {this.props.block.content.nameOn &&
              <input type='text' name='name'
                     placeholder={ isRu ? 'Ваше Имя' : 'Name'}
                     className='form-control'
                     value={this.state.name}
                     onChange={event => this.handleChange({name: event.target.value})}/>
            }
            {this.props.block.content.emailOn &&
              <input type='text' name='email'
                     placeholder={ isRu ? 'Ваш E-mail' : 'Email'}
                     className='form-control'
                     value={this.state.email}
                     onChange={event => this.handleChange({email: event.target.value})}/>
            }
            {this.props.block.content.phoneOn &&
              <input type='text' name='phone'
                     placeholder={ isRu ? 'Ваш телефон' : 'Phone'}
                     className='form-control'
                     value={this.state.phone}
                     onChange={event => this.handleChange({phone: event.target.value})}/>
            }
            {this.props.block.content.commentOn &&
              <textarea name='message'
                        placeholder={ isRu ? 'Комментарий' : 'Your message'}
                        className='form-control'
                        value={this.state.message}
                        onChange={event => this.handleChange({message: event.target.value})}/>
            }
            <button className='btn btn-primary g-full-width'>
              {block.content.buttonText}
            </button>
          </form>
        }
      </div>
    )
  }

}
