import React from 'react'
import Slider from 'react-slick'
import { BlockProps } from '../Landing'
import ResponsiveImage from '../shared/ResponsiveImage';

interface CarouselContent {
  slides: [{
    imageUrl: string
    imageHref: string
  }]
}

export default class extends React.Component<BlockProps<CarouselContent>, any> {
  render() {
    const {block, onClick} = this.props
    if (block.content && Array.isArray(block.content.slides)) {
      const onSlideClick = () => onClick();
      return (
        <div>
          <Slider dots={true}>
            {block.content.slides.map((slide, index) => (
              <a key={index} href={slide.imageHref || '#'} onClick={onSlideClick} target='_blank'>
                <ResponsiveImage src={slide.imageUrl}/>
              </a>
            ))}
          </Slider>
        </div>
      )
    }
    return null
  }
}
