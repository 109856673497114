import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Avatar.less';
import ResponsiveImage from '../shared/ResponsiveImage';

export default class extends React.Component<BlockProps, any> {
  render() {
    const { block: { content: { avatar, text, title } }, onClick } = this.props;
    return (
      <div className={css.avatar} onClick={() => onClick()}>
        <div className={css.ava}>
          <ResponsiveImage src={avatar} />
        </div>
        <div className={css.info}>
          <header>
            <h3>{title}</h3>
          </header>
          <div className={css.text}>
            <p>{text}</p>
          </div>
        </div>
      </div>
    )
  }
}
