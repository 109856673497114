import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Button.less';

export default class extends React.Component<BlockProps, any> {

  render() {
    const { block, onClick } = this.props;
    const { color = '#fff', backgroundColor = '#1a6bda' } = block.content;
    let { link: href } = block.content;
    if (href && href.startsWith && !href.startsWith('http') && !href.startsWith('//')) {
      href = 'http://' + href;
    }
    return (
      <div className={css.buttonBlock}>
        <a className='btn g-full-width' href={href} target='_blank' style={{color, backgroundColor}}
           onClick={() => onClick()}>
          {block.content.title}
        </a>
      </div>
    )
  }

}
