import React, { FC } from 'react';
import CyrillicToTranslit from 'cyrillic-to-translit-js'
const translator = new CyrillicToTranslit();

const getSrc = (width: number, key: string, isFallback?: boolean) => {
  return `https://images.avatap.ru/${key}`
  const origin = JSON.stringify({
    key,
    bucket: 'avatap-uploads',
    edits: { resize: { width, fit: 'contain' } }
  });
  const src = `https://images.avatap.ru/${Buffer.from(origin).toString('base64')}`;
  return isFallback ? src : `${src} ${width}w`;
};

const ResponsiveImage: FC<{src: string}> = ({ src }) => {
  //DO NOT CHANGE !!!
  src = src.replace('//uploads.avatap.cc/', '');
  if (translator.transform(src, '_') !== src) {
    return <img src={src.startsWith('files/') ? '//uploads.avatap.ru/' + src : src} alt='' />;
  }
  if (src.startsWith('http') || src.startsWith('//')) {
    return <img src={src} alt='' />;
  }
  return (
    <img
      sizes="(max-width: 560px) 490px, (max-width: 840px) 800px, 1200px"
      srcSet={`${getSrc(490, src)}, ${getSrc(800, src)}, ${getSrc(1200, src)}`}
      src={getSrc(490, src, true)}
      alt=''
    />
  );
}

export default ResponsiveImage;
