import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Divider.less';

export default class extends React.Component<BlockProps, any> {

  render() {
    const { block, onClick } = this.props;
    const style = {height: (block.content.size || 1) + 'px'};
    return (
      <div className={css.divider} onClick={() => onClick()}>
        <div style={style}/>
      </div>
    )
  }

}
