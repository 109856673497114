import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Timer.less';
import { DateTime } from 'luxon'

interface State {
  days: string
  hours: string
  minutes: string
  seconds: string
}

const InitialState = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00'
}

function toDblDigit(num: number | string) {
  return ('0' + num).slice(-2);
}

export default class extends React.Component<BlockProps, State> {
  timeoutId: any;
  state = InitialState;

  componentDidMount() {
    const { block: { content: { date } } } = this.props
    const secondsLeft = date ?
      Math.max(Math.round(DateTime.fromISO(date, {zone: 'utc'}).toSeconds() - DateTime.utc().toSeconds()), 0) :
      0;
    this.loop(secondsLeft);
  }

  loop(secondsLeft: number|null) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (!secondsLeft || secondsLeft < 0) {
      this.setState(InitialState);
      return;
    }
    let secondsToParse = secondsLeft;
    const days = toDblDigit(Math.floor(secondsToParse / ( 24 * 3600 )));
    secondsToParse = secondsToParse % ( 24 * 3600 );
    const hours = toDblDigit(Math.floor(secondsToParse / 3600));
    secondsToParse = secondsToParse % 3600;
    const minutes = toDblDigit(Math.floor(secondsToParse / 60));
    const seconds = toDblDigit(secondsToParse % 60);
    this.setState({ days, hours, minutes, seconds });
    this.timeoutId = setTimeout(() => this.loop(secondsLeft - 1), 1000);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
  render() {
    const { days, hours, minutes, seconds } = this.state;
    const { block: { content: { title, theme } }, landing: {locale} } = this.props;
    const isRu = locale === 'ru'
    let classNames = css.timer_cell;
    if (theme === 'dark') {
      classNames += ' ' + css.timer_cell_dark;
    }
    return (
      <div className={css.timer}>
        {title && <header><h3>{title}</h3></header>}
        <div>
          <div className={classNames}>
            <span>{ days[0] }</span>
            <span>{ days[1] }</span>
            <small>{ isRu ? 'дней' : 'days'}</small>
          </div>
          <div className={classNames}>
            <span>{ hours[0] }</span>
            <span>{ hours[1] }</span>
            <small>{ isRu ? 'часов' : 'hours'}</small>
          </div>
          <div className={classNames}>
            <span>{ minutes[0] }</span>
            <span>{ minutes[1] }</span>
            <small>{ isRu ? 'минут' : 'minutes'}</small>
          </div>
          <div className={classNames}>
            <span>{ seconds[0] }</span>
            <span>{ seconds[1] }</span>
            <small>{ isRu ? 'секунд' : 'seconds'}</small>
          </div>
        </div>
      </div>
    );
  }
}
