export default function SvgTag() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66675 15.7067C4.66675 16.1421 4.81857 16.5114 5.12251 16.8155L11.1723 22.8776C11.4926 23.1815 11.8667 23.3334 12.2935 23.3334C12.7292 23.3334 13.0985 23.1815 13.4025 22.8776L22.2121 14.0555C22.5243 13.7515 22.7891 13.3369 23.0069 12.8111C23.2246 12.2852 23.3333 11.8049 23.3333 11.3695V6.24387C23.3333 5.81667 23.1772 5.44704 22.8651 5.1349C22.553 4.82293 22.1834 4.66675 21.7562 4.66675H16.6305C16.1951 4.66675 15.7146 4.77559 15.1888 4.99322C14.6631 5.21081 14.2443 5.47561 13.9321 5.78797L5.12234 14.5853C4.81857 14.9058 4.66675 15.2795 4.66675 15.7067ZM17.8134 8.60961C17.8134 8.17426 17.9675 7.80252 18.2755 7.4946C18.5835 7.18652 18.9552 7.03241 19.3906 7.03241C19.8259 7.03241 20.1976 7.18652 20.5057 7.4946C20.8137 7.80252 20.9677 8.17422 20.9677 8.60961C20.9677 9.04497 20.8137 9.41654 20.5057 9.72463C20.1976 10.0327 19.8259 10.1868 19.3906 10.1868C18.9552 10.1868 18.5837 10.0327 18.2755 9.72463C17.9674 9.41654 17.8134 9.04497 17.8134 8.60961Z"
        fill="#FE9900"
      />
    </svg>
  )
}
