import React from 'react'
import Landing, { LandingData } from '../components/Landing'
import getConfig from 'next/config'
import Error from 'next/error'
import Head from 'next/head'
import fetch from 'isomorphic-unfetch'
import { NextContext } from 'next'

const {serverRuntimeConfig, publicRuntimeConfig} = getConfig()

const apiEndpoint: string = serverRuntimeConfig.apiEndpoint || publicRuntimeConfig.apiEndpoint

export interface PageProps {
  landingData?: LandingData
}

export default class extends React.Component<PageProps, any> {

  static async getInitialProps(ctx: NextContext): Promise<PageProps> {
    let url = apiEndpoint
    if (ctx.query.host) {
      url = url + '/landing_data_by_domain/' + encodeURIComponent(String(ctx.query.host))
    } else {
      if (ctx.query.name) {
        url = url + '/landing_data/' + encodeURIComponent(String(ctx.query.name))
      }
    }
    return fetch(url)
      .then(response => {
        if (response.status !== 200) {
          return null
        }
        return response.json()
      })
      .then(data => ({ landingData: data, }))
  }

  preparePageSettings() {
    const page = this.props.landingData
    let keywords = null
    let title = ''
    if (page && page.settings) {
      title = page.settings.title || ''
      keywords = page.settings.keywords || ''
    }
    return {title, keywords}
  }

  prepareOgTagsContent() {
    const page = this.props.landingData
    let ogDescription = null
    let ogAvatar = null
    let ogTitle = null
    if (page && page.blocks) {
      const avatarBlock = page.blocks.find(b => b.blockType === 'avatar')
      if (avatarBlock) {
        ogDescription = avatarBlock.content.text
        ogAvatar = avatarBlock.content.avatar
        ogTitle = avatarBlock.content.title
      }
    }
    return {ogTitle, ogAvatar, ogDescription}
  }


  render() {
    if (this.props.landingData) {
      const {title, keywords} = this.preparePageSettings()
      const {ogTitle, ogAvatar, ogDescription} = this.prepareOgTagsContent()
      return (
        <React.Fragment>
          <Head>
            <title>{title}</title>
            <meta name='viewport' content='width=device-width, initial-scale=1'/>
            {keywords && <meta name='keywords' content={keywords}/>}
            {ogTitle && <meta property='og:title' content={ogTitle}/>}
            {ogAvatar && <meta property='og:avatar' content={ogAvatar}/>}
            {ogDescription && <meta property='og:description' content={ogDescription}/>}
            <link rel='icon' type='image/x-icon' href='/static/favicon.ico'/>
            <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css'/>
            <script src='https://code.jquery.com/jquery-3.3.1.slim.min.js'></script>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js'></script>
            <script src='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js'></script>
          </Head>
          <Landing landingData={this.props.landingData}/>
        </React.Fragment>
      )
    }
    return <Error statusCode={404}/>
  }

}
