import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Html.less';

export default class extends React.Component<BlockProps, any> {

  render() {
    const { block, onClick } = this.props;
    return (
      <div className={css.html} onClick={() => onClick()}>
        <div dangerouslySetInnerHTML={{__html: block.content.html}}/>
      </div>
    )
  }

}
