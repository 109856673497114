import React from 'react';
import { BlockProps } from '../Landing';
import * as css from './Video.less';

export default class extends React.Component<BlockProps, any> {

  render() {
    const { block, onClick } = this.props;
    const innerHTML = { __html: block.content.html };
    if (!block.content.html) {
      return null;
    }
    return <div className={css.video} dangerouslySetInnerHTML={innerHTML} onClick={() => onClick()}/>;
  }

}
